import React from 'react';

import Category from 'components/help/category';

import { developer } from 'data/helpArticles';

export default () => (
  <Category
    title="Developer"
    articles={developer}
    url="/help/developer/"
    description="Find answers to your technical problems."
  />
);
